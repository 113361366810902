import {reactive, watch} from "vue";
export const store = reactive({
    navigationOpen: false,
    modalOpen: false,
    toggle: () => store.navigationOpen = !store.navigationOpen,
    toggleModal: () => store.modalOpen = !store.modalOpen,
});

export const onKeyEscape = (event) => {
    if (event.key === 'Escape') store.navigationOpen = false;
    if (event.key === 'Escape') store.modalOpen = false;
};

watch(() => store.navigationOpen, (value) => {
    if (value) {
          window.addEventListener('keydown', onKeyEscape);
        document.documentElement.classList.add('overflow-hidden');
    } else {
            window.removeEventListener('keydown', onKeyEscape);
        document.documentElement.classList.remove('overflow-hidden');
    }
}, {immediate: true});


watch(() => store.modalOpen, (value) => {
    if (value) {
        window.addEventListener('keydown', onKeyEscape);
        document.documentElement.classList.add('overflow-hidden');
    } else {
        window.removeEventListener('keydown', onKeyEscape);
        document.documentElement.classList.remove('overflow-hidden');
    }
}, {immediate: true});

