import { createApp } from 'vue';

import NavigationButton from "@components/NavigationButton.vue";
import Navigation from "@components/Navigation.vue";
import ParallaxScroll from "@components/ParallaxScroll.vue";
import Overlay from  "@components/Overlay.vue";

import { register } from 'swiper/element/bundle';

import '@styles/app.pcss';

const app = createApp({
    components: {
        Navigation,
        NavigationButton,
        ParallaxScroll,
        Overlay
    },
});

app.mount('#app');
register();


